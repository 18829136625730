import { FC, useEffect, useRef, useState } from 'react';
import { findcolor, RaceDatas } from '../types';
import './odds.scss';
import { RunnerSpeedDatas, useSpeedsContext } from '../pages/race';
import { Switch } from 'antd';
import { Nump } from './nump';

/* -------------------------------------------------------------------------------- *\
|                               Constantes
\* -------------------------------------------------------------------------------- */
const c_fontsize_label: number = 10;
const c_fontsize_number: number = 8;
const c_percentdep: number = 0.10;
const c_xdep: number = 90;
const c_xarr: number = 90;
const c_width: number = 300;
const c_height: number = 300;
const yaxismargin = 5;
const yaxismargin2 = 3;
const yaxismargin3 = 2;
const textmargin = 8;

const sbheight = 16;
const xsbmargin = 1;
const ysbmargin = 1;

/* -------------------------------------------------------------------------------- *\
|                               Variables
\* -------------------------------------------------------------------------------- */
var g_odds_mousex: number = -1;
var g_odds_mouseselx1: number = -1;
var g_odds_mouseselx2: number = 0;
var g_odds_percent1: number = 0;
var g_odds_percent2: number = 1;

var g_odds_xfirst: number = c_xdep;
var g_odds_xpcdep: number = c_xdep;
var g_odds_xpcarr: number = c_width - c_xarr;
var g_odds_yaxistop: number = 20;
var g_odds_yaxisbottom: number = c_height - 50;

var g_odds_ysbtop: number = g_odds_yaxisbottom + 24;
var g_odds_sbactive: boolean = false;
var g_odds_sbmove: boolean = false;
var g_odds_sbfirstx: number = -1;

/* -------------------------------------------------------------------------------- *\
|                               RunnerSelection
\* -------------------------------------------------------------------------------- */
class RunnerSelection
{
    public number: number = 0;
    public selected: boolean = false;
}
/* -------------------------------------------------------------------------------- *\
|                               OddsProps
\* -------------------------------------------------------------------------------- */
interface OddsProps
{
	race: RaceDatas,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               Odds
\* -------------------------------------------------------------------------------- */
export const Odds : FC<OddsProps> = ({race}) =>
{
    const refcontainer = useRef<HTMLDivElement>(null);
    const refcanvas = useRef<HTMLCanvasElement>(null);

    const [distance, setDistance] = useState<number>(0);
    const [width, setWidth] = useState<number>(300);
    const [height, setHeight] = useState<number>(300);
    const speeds = useSpeedsContext();

    const [autoscale, setAutoscale] = useState<boolean>(false);
    const [follow, setFollow] = useState<boolean>(true);

    const [selected, setSelected] = useState<RunnerSelection[]>([]);
    const [withodds, setWithOdds] = useState<boolean>(false);

    /* -------------------------------------------------------------------------------- *\
    |                               drawGraph
    \* -------------------------------------------------------------------------------- */
    function drawGraph(ctx: CanvasRenderingContext2D)
    {
        g_odds_xfirst = c_xdep;
        g_odds_xpcdep = c_xdep;
        g_odds_xpcarr = width - c_xarr;
        g_odds_yaxistop = 20;
        g_odds_yaxisbottom = height - 50;
        const gheight = g_odds_yaxisbottom - g_odds_yaxistop;

        g_odds_ysbtop = g_odds_yaxisbottom + 24;
        // g_ysbbottom = g_ysbtop + sbheight;

        ctx.fillStyle = "black";
        ctx.beginPath();
        ctx.rect(0, 0, width, height);
        ctx.fill();

        if (g_odds_mouseselx1 !== g_odds_mouseselx2)
        {
            var x1 = g_odds_mouseselx1 + g_odds_xpcdep;
            var x2 = g_odds_mouseselx2 + g_odds_xpcdep;
            if (x2 < x1)
            {
                var t = x1;
                x1 = x2;
                x2 = t;
            }
            ctx.fillStyle = "#444444";
            ctx.beginPath();
            ctx.rect(x1, 0, x2 - x1, g_odds_yaxisbottom);
            ctx.fill();
        }

        ctx.strokeStyle = "white";
        ctx.lineWidth = 1;
        ctx.beginPath();
        ctx.moveTo(g_odds_xpcdep, g_odds_yaxistop);
        ctx.lineTo(g_odds_xpcdep, g_odds_yaxisbottom + yaxismargin);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(g_odds_xpcarr, g_odds_yaxistop);
        ctx.lineTo(g_odds_xpcarr, g_odds_yaxisbottom + yaxismargin);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(g_odds_percent1 === 0 ? g_odds_xfirst : g_odds_xpcdep, g_odds_yaxisbottom);
        ctx.lineTo(g_odds_xpcarr, g_odds_yaxisbottom);
        ctx.stroke();

        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.font = `${c_fontsize_label}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
        if (g_odds_percent1 === 0) ctx.fillText("Start", g_odds_xpcdep, g_odds_yaxisbottom + yaxismargin + textmargin);
        if (g_odds_percent2 === 1) ctx.fillText("Finish", g_odds_xpcarr, g_odds_yaxisbottom + yaxismargin + textmargin);

        var drawstart: number = g_odds_percent1 * distance;
        var drawend: number = g_odds_percent2 * distance;

        const delta: number = (drawend - drawstart < 250) ? 25 : 100;

        // Affichage des distances sous l'axe des xx
        var lastx = g_odds_xpcarr;
        for (var d = 0; d < distance; d += delta)
        {
            var xt: number = (distance - d);
            xt = (xt - drawstart) / (drawend - drawstart);
            xt *=  (g_odds_xpcarr - g_odds_xpcdep);
            xt += g_odds_xpcdep;

            if (xt >= g_odds_xpcdep && xt <= g_odds_xpcarr)
            {
                ctx.beginPath();
                ctx.moveTo(xt, g_odds_yaxisbottom - yaxismargin2);
                ctx.lineTo(xt, g_odds_yaxisbottom + yaxismargin2);
                ctx.stroke();
                if (lastx - xt > 30)
                {
                    ctx.fillText(d.toString(), xt, g_odds_yaxisbottom + yaxismargin + textmargin);
                    lastx = xt;
                }
            }
        }

        for(const speed of speeds)
        {
            if (!speed.speeds || !speed.selected) continue;

            for (const ss of speed.speeds.racespeeds)
            {
                var x: number = ss.dpdp;
                x = (x - drawstart) / (drawend - drawstart);
                x *=  (g_odds_xpcarr - g_odds_xpcdep);
                x += g_odds_xpcdep;

                ss.workx = x;
            }
        }

        var oddmin: number = 0;
        var oddmax: number = RunnerSpeedDatas.maxodds;
        const marginodd: number = 2;
        if (autoscale)
        {
            oddmin = RunnerSpeedDatas.maxodds;
            oddmax = 0;
            for(const speed of speeds)
            {
                if (!speed.speeds || !speed.selected) continue;

                for (const ss of speed.speeds.racespeeds)
                {
                    if (ss.workx >= g_odds_xpcdep && ss.workx <= g_odds_xpcarr)
                    {
                        oddmin = Math.min(ss.odd, oddmin);
                        oddmax = Math.max(ss.odd, oddmax);
                    }
                }
            }
            oddmin = Math.max(0, oddmin - marginodd);
            oddmax = Math.min(RunnerSpeedDatas.maxodds, oddmax + marginodd);
        }
        if (oddmin >= oddmax)
        {
            oddmin =  0;
            oddmax = RunnerSpeedDatas.maxodds;
        }

        var deltaodd: number = oddmax - oddmin;
        var scalestep: number = 5;
        var scalesteptext: number = 10;
        if (deltaodd <= 5)
        {
            scalestep = 0.5;
            scalesteptext = 1;
        }
        else if (deltaodd <= 20)
        {
            scalestep = 1;
            scalesteptext = 5;
        }

        if (deltaodd < marginodd * 2)
        {
            oddmin = (oddmin + oddmax) / 2 - marginodd;
            oddmax = (oddmin + oddmax) / 2 + marginodd;
        }
        
        // Affichage de l'échelle de l'axe des y
        ctx.textAlign = "left";
        for (var u = scalestep; u <= oddmax; u += scalestep)
        {
            const yu = g_odds_yaxistop + gheight - ((u - oddmin) / (oddmax - oddmin)) * gheight;

            if (yu >= 0 && yu <= gheight)
            {
                ctx.beginPath();
                const tens: boolean = (u % scalesteptext) === 0;
                const m = tens ? yaxismargin2 : yaxismargin3;
                ctx.moveTo(g_odds_xpcarr - m, yu);
                ctx.lineTo(g_odds_xpcarr + m, yu);
                ctx.stroke();
                if (tens)ctx.fillText(u.toString(), g_odds_xpcarr + yaxismargin2 + 5, yu);
            }
        }
        ctx.font = `${c_fontsize_number}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;

        ctx.save();
        const xclip: number = g_odds_percent1 === 0 ? g_odds_xfirst : g_odds_xpcdep;
        ctx.rect(xclip, 0, g_odds_xpcarr - xclip, height);
        ctx.clip();
        for(const speed of speeds)
        {
            if (!speed.speeds || !speed.selected) continue;

            const color = findcolor(speed.number);
            ctx.strokeStyle = '#' + color.color;
            var xnum = g_odds_xpcdep;
            ctx.beginPath();
            var lastodd: number = 0;
            if (distance > 0)
            {
                for (var i = 0; i < speed.speeds.racespeeds.length; i++)
                {
                    x = speed.speeds.racespeeds[i].workx;

                    const o: number = i < speed.speeds.racespeeds.length - 1 ? speed.speeds.racespeeds[i].odd : speed.odd;
                    const y = g_odds_yaxistop + gheight - ((o - oddmin) / (oddmax - oddmin)) * gheight;
                    if (lastodd === 0 && i === 0)
                    {
                        ctx.moveTo(x, y);
                    }
                    else
                    {
                        ctx.lineTo(x, y);
                    }
                    xnum = x;
                }
            }
            ctx.stroke();
            speed.last_x = xnum;
            speed.last_y = g_odds_yaxistop + gheight - ((speed.odd  - oddmin) / (oddmax - oddmin)) * gheight;;
        }
        ctx.restore();
        
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        
        for(const speed of speeds)
        {
            if (!speed.speeds || !speed.selected) continue;

            speed.last_x = Math.min(speed.last_x, g_odds_xpcarr);
            if (speed.last_x > g_odds_xpcdep || g_odds_percent1 === 0)
            {
                const color = findcolor(speed.number);

                ctx.beginPath();
                ctx.fillStyle = '#' + color.color;
                ctx.ellipse(speed.last_x, speed.last_y, 7, 7, 0, 0, 2 * Math.PI);
                ctx.fill();

                ctx.fillStyle = '#' + color.font;
                ctx.fillText(String(speed.number), speed.last_x, speed.last_y);
            }
        }

        // Curseur
        if (g_odds_mousex >= 0)
        {        
            ctx.strokeStyle = "white";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(g_odds_mousex + g_odds_xpcdep, 0);
            ctx.lineTo(g_odds_mousex + g_odds_xpcdep, g_odds_yaxisbottom);
            ctx.stroke();
        }

        // Scrollbar
        ctx.fillStyle = "#262626";
        ctx.beginPath();
        ctx.rect(g_odds_xpcdep, g_odds_ysbtop, g_odds_xpcarr - g_odds_xpcdep, sbheight);
        ctx.fill();

        var [xsb, ysb, wsb, hsb] = computesb();
        ctx.fillStyle = (g_odds_sbactive || g_odds_sbmove) ? "#e0a642" : "#a78349";
        ctx.beginPath();
        ctx.rect(xsb, ysb, wsb, hsb);
        ctx.fill();
        
    }
    /* -------------------------------------------------------------------------------- *\
    |                               computex
    \* -------------------------------------------------------------------------------- */
    function computex(x: number, width: number): number
    {
        const xpcdep = c_xdep;
        const xpcarr = width - c_xarr;
        if (x < xpcdep) x = xpcdep;
        if (x > xpcarr) x = xpcarr;

        return x - xpcdep;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               computesb
    \* -------------------------------------------------------------------------------- */
    function computesb(): [number, number, number, number]
    {
        const hsbtotal = sbheight - ysbmargin * 2;
        const wsbtotal = (g_odds_xpcarr - g_odds_xpcdep) - xsbmargin * 2;
        const xsb = wsbtotal * g_odds_percent1 + g_odds_xpcdep + xsbmargin;
        const wsb = wsbtotal * (g_odds_percent2 - g_odds_percent1);
        return [xsb, g_odds_ysbtop + ysbmargin, wsb, hsbtotal];
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseMove
    \* -------------------------------------------------------------------------------- */
    function handleMouseMove(e: React.MouseEvent, width: number)
    {
        if (g_odds_mouseselx1 >= 0)
        {
            g_odds_mousex = computex(e.nativeEvent.offsetX, width);
            g_odds_mouseselx2 = g_odds_mousex;
        }
        else if (e.nativeEvent.offsetY < g_odds_yaxisbottom)
        {
            g_odds_mousex = computex(e.nativeEvent.offsetX, width);
        }
        else
        {
            g_odds_mousex = -1;
        }

        if (!g_odds_sbmove)
        {
            var [xsb, ysb, wsb, hsb] = computesb();
            if (e.nativeEvent.offsetX >= xsb && e.nativeEvent.offsetX <= xsb + wsb && e.nativeEvent.offsetY >= ysb && e.nativeEvent.offsetY <= ysb + hsb)
            {
                g_odds_sbactive = true;
            }
            else
            {
                g_odds_sbactive = false;
            }
        }
        else
        {
            const wsbtotal = (g_odds_xpcarr - g_odds_xpcdep) - xsbmargin * 2;
            const wsb = wsbtotal * (g_odds_percent2 - g_odds_percent1);
            const xsb = wsbtotal * g_odds_percent1;

            var d = e.nativeEvent.offsetX - g_odds_sbfirstx;
            var nx = xsb + d;
            if (nx < 0) nx = 0;
            if (nx + wsb > wsbtotal) nx = wsbtotal - wsb;

            g_odds_percent1 = nx / wsbtotal;
            g_odds_percent2 = (nx + wsb) / wsbtotal;

            g_odds_sbfirstx = e.nativeEvent.offsetX;
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseLeave
    \* -------------------------------------------------------------------------------- */
    function handleMouseLeave(e: React.MouseEvent, width: number)
    {
        g_odds_mousex = -1;
        g_odds_mouseselx1 = -1;
        g_odds_mouseselx2 = -1;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dozoomfull
    \* -------------------------------------------------------------------------------- */
    function dozoomfull()
    {
        g_odds_percent1 = 0;
        g_odds_percent2 = 1;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dozoomout
    \* -------------------------------------------------------------------------------- */
    function dozoomout()
    {
        var d: number = g_odds_percent2 - g_odds_percent1;
        g_odds_percent1 = g_odds_percent1 - d / 2;
        g_odds_percent2 = g_odds_percent2 + d / 2;
        if (g_odds_percent1 < 0) g_odds_percent1 = 0;
        if (g_odds_percent2 > 1) g_odds_percent2 = 1;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dozoomin
    \* -------------------------------------------------------------------------------- */
    function dozoomin()
    {
        var d: number = g_odds_percent2 - g_odds_percent1;
        var middle: number = (g_odds_percent1 + g_odds_percent2) / 2;
        var p1 = middle - d / 4;
        var p2 = middle + d / 4;
        if (p1 < 0) p1 = 0;
        if (p2 > 1) p2 = 1;

        if ((p2 - p1) > 0.05)
        {
            g_odds_percent1 = p1;
            g_odds_percent2 = p2;
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseDown
    \* -------------------------------------------------------------------------------- */
    function handleMouseDown(e: React.MouseEvent, width: number)
    {
        if (e.nativeEvent.offsetY < g_odds_yaxisbottom)
        {
            if (e.nativeEvent.button === 0)
            {
                g_odds_mouseselx1 = computex(e.nativeEvent.offsetX, width);
                g_odds_mouseselx2 = g_odds_mouseselx1;
                e.stopPropagation();
            }
            if (e.nativeEvent.button === 2)
            {
                dozoomout();
                e.stopPropagation();
            }
        }
        var [xsb, ysb, wsb, hsb] = computesb();
        if (e.nativeEvent.offsetX >= xsb && e.nativeEvent.offsetX <= xsb + wsb && e.nativeEvent.offsetY >= ysb && e.nativeEvent.offsetY <= ysb + hsb)
        {
            g_odds_sbfirstx = e.nativeEvent.offsetX;
            g_odds_sbmove = true;
            setFollow(false);
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseUp
    \* -------------------------------------------------------------------------------- */
    function handleMouseUp(e: React.MouseEvent, width: number)
    {
        if (e.nativeEvent.button === 0)
        {
            if (width > 0 && g_odds_mouseselx1 >= 0)
            {
                g_odds_mouseselx2 = computex(e.nativeEvent.offsetX, width);
                const xpcdep = width * c_percentdep;
                const xpcarr = width - c_xarr;
                var p1 = g_odds_mouseselx1 / (xpcarr - xpcdep);
                var p2 = g_odds_mouseselx2 / (xpcarr - xpcdep);
                if (p2 < p1)
                {
                    var tmp = p1;
                    p1 = p2;
                    p2 = tmp;
                }
                if ((p2 - p1) > 0.05)
                {
                    var oldw: number = g_odds_percent2 - g_odds_percent1;
                    var selw: number = p2 - p1;
                    if (oldw > 0)
                    {
                        g_odds_percent1 += p1 * oldw;
                        g_odds_percent2 = g_odds_percent1 + selw * oldw;
                    }
                }
            }
            g_odds_mouseselx1 = -1;
            g_odds_mouseselx2 = -1;

            if (g_odds_sbmove)
            {
                g_odds_sbmove = false;
            }
        }
        if (e.nativeEvent.button === 2)
        {
            e.stopPropagation();
        }
    }

    /* -------------------------------------------------------------------------------- *\
    |                               doselectall
    \* -------------------------------------------------------------------------------- */
    function doselectall()
    {
        const sel = selected.map((x) => {x.selected = true; return x;});
        setSelected(sel);
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dodeselectall
    \* -------------------------------------------------------------------------------- */
    function dodeselectall()
    {
        const sel = selected.map((x) => {x.selected = false; return x;});
        setSelected(sel);
    }

    useEffect(() =>
    {
        if (refcontainer && refcontainer.current && width !== refcontainer.current.clientWidth)
        {
            setWidth(refcontainer.current.clientWidth);
        }
        if (refcontainer && refcontainer.current && height !== refcontainer.current.clientHeight)
        {
            setHeight(refcontainer.current.clientHeight);
        }
        
        var sel: RunnerSelection[] = [];
        setDistance(race.distance);

        if (withodds !== race.odds)
        {
            setWithOdds(race.odds);
        }

        var diff: boolean = false;
        for (const p of race.positions)
        {
            if (!selected.find((x) => x.number === p.number))
            {
                diff = true;
                sel.push({number: p.number, selected: true});
            }
        }
        if (diff)
        {
            sel.sort((a, b) => a.number - b.number);
            setSelected(sel);
        }
        // eslint-disable-next-line
    }, [race]);

    useEffect(() =>
    {
        const canvas = refcanvas.current;
        if (canvas)
        {
            for (const speed of speeds)
            {
                if (speed)
                {
                    speed.selected = selected.find((x) => x.number === speed.number)?.selected ? true : false;
                }
            }
            if (follow && distance > 0)
            {
                var dpdpmax: number = 0;
                for (const speed of speeds)
                {
                    if (speed && speed.speeds && speed.speeds.racespeeds.length > 0 && speed.selected)
                    {
                        const sd: number = speed.speeds.racespeeds[speed.speeds.racespeeds.length - 1].dpdp;
                        if (sd > dpdpmax)
                        {
                            dpdpmax = sd;
                        }
                    }
                }
                var percent: number = dpdpmax / distance;
                const deltamid: number = (g_odds_percent2 - g_odds_percent1) / 2;

                if (percent + deltamid > 1)
                {
                    percent = 1 - deltamid;
                }
                if (percent - deltamid < 0)
                {
                    percent = deltamid;
                }
                g_odds_percent1 = percent - deltamid;
                g_odds_percent2 = percent + deltamid;
            }
            const context = canvas.getContext('2d');
            if (context)
            {
                drawGraph(context);
            }
        }
        // eslint-disable-next-line
    }, [width, height, speeds, distance, autoscale, follow, selected, withodds]);

    return (
        <div className='odds'>
            <div className='odds__selection'>
                {
                    selected.map((s, i) =>
                    {
                        return (
                            <div className='odds__selection__runner' key={`sel_${i}`} >
                                <Nump number={s.number} size={16} />
                                <Switch size="small" checked={s.selected} onChange={() => setSelected(selected.map((x) => x.number === s.number ? {number: x.number, selected: !x.selected} : x))} />
                            </div>
                        )
                    })
                }
                <div className='odds__selection__button' onClick={() => doselectall()}>select all</div>
                <div className='odds__selection__button' onClick={() => dodeselectall()}>deselect all</div>
            </div>
            <div className='odds__datas'>
                <div className='odds__datas__graph' ref={refcontainer}>
                    <canvas ref={refcanvas} width={width} height={height}
                    onContextMenu={e => e.preventDefault()}
                    onMouseMove={e => handleMouseMove(e, width)}
                    onMouseDownCapture ={e => handleMouseDown(e, width)}
                    onMouseUp={e => handleMouseUp(e, width)}
                    onMouseLeave={e => handleMouseLeave(e, width)}/>
                </div>
                <div className='odds__datas__toolbar'>
                    <div className='odds__datas__toolbar__button' onClick={() => dozoomin()}>Zoom in</div>
                    <div className='odds__datas__toolbar__button' onClick={() => dozoomout()}>Zoom out</div>
                    <div className='odds__datas__toolbar__button' onClick={() => dozoomfull()}>Full</div>
                    <div className='odds__datas__toolbar__switch'>
                        <div>follow</div>
                        <Switch size="small" checked={follow} onChange={() => setFollow(!follow)} />
                    </div>
                    <div className='odds__datas__toolbar__switch'>
                        <div>scale</div>
                        <Switch size="small" checked={autoscale} onChange={() => setAutoscale(!autoscale)} />
                    </div>
                </div>
            </div>
        </div>
    );
}
