import axios from "axios";
import { ApiActiveRace, ApiRace } from "../types";
import { authHeader, hasAuth } from "./auth.header";

/* -------------------------------------------------------------------------------- *\
|                               findapiserverurl
\* -------------------------------------------------------------------------------- */
export const findapiserverurl = (): string =>
{
	if (process.env.REACT_APP__API_URL) return process.env.REACT_APP__API_URL;

	return 'https://spectator.mclloyd.com';
}
/* -------------------------------------------------------------------------------- *\
|                               findwsserverurl
\* -------------------------------------------------------------------------------- */
export const findwsserverurl = (): string =>
{
	if (process.env.REACT_APP__WS_URL) return process.env.REACT_APP__WS_URL;

	return 'https://spectator.mclloyd.com/ws';
}
/* -------------------------------------------------------------------------------- *\
|                               findappserverurl
\* -------------------------------------------------------------------------------- */
export const findportailappserverurl = (): string =>
{
	if (process.env.REACT_APP_SERVER_URL) return process.env.REACT_APP_SERVER_URL;

	return 'https://portail-data-api.mclloyd.com';
}
/* -------------------------------------------------------------------------------- *\
|                               get_meeting_list
\* -------------------------------------------------------------------------------- */
export const get_meeting_list = async (date: string) : Promise<ApiRace[]> =>
{
	if (!hasAuth())
	{
		return Promise.resolve([]);
	}
	return Promise.resolve()
		.then(() => axios.get(findportailappserverurl() + `/api/v2/${date}/cards`, { headers: authHeader() }))
		.then(response => 
		{
			var races = response.data as ApiRace[];
			for(var race of races)
			{
				race.date = race.date.replace(/\//g, '');
				const sa: string[] = race.hours.split(':');
				const ta: number = Number(sa[0]) * 60 + Number(sa[1]);
				race.start = ta;
			}
			return races;
		});
}
/* -------------------------------------------------------------------------------- *\
|                               get_race
\* -------------------------------------------------------------------------------- */
export const get_race = async (date: string, track: string, race: number) : Promise<ApiRace> =>
{
	if (!hasAuth())
	{
		return Promise.resolve(new ApiRace());
	}
	return Promise.resolve()
		.then(() => axios.get(findportailappserverurl() + `/api/v2/${date}/venue/${track}/race/${race}/card`, { headers: authHeader() }))
		.then(response => 
		{
			var race = response.data as ApiRace;
			race.date = race.date.replace(/\//g, '');
			const sa: string[] = race.hours.split(':');
			const ta: number = Number(sa[0]) * 60 + Number(sa[1]);
			race.start = ta;
			return race;
		});
}
/* -------------------------------------------------------------------------------- *\
|                               get_activeraces
\* -------------------------------------------------------------------------------- */
export const get_activeraces = async () : Promise<ApiActiveRace[]> =>
{
	if (!hasAuth())
	{
		return Promise.resolve([]);
	}
	return Promise.resolve()
		.then(() => axios.get(findapiserverurl() + '/api/activeraces'))
		.then(response => 
		{
			var races = response.data as ApiActiveRace[];
			return races;
		});
}
  