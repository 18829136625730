import { FC } from 'react';
import './gauge.scss';

/* -------------------------------------------------------------------------------- *\
|                               GaugeProps
\* -------------------------------------------------------------------------------- */
interface GaugeProps
{
	percent: number,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               Gauge
\* -------------------------------------------------------------------------------- */
export const Gauge : FC<GaugeProps> = ({percent}) =>
{
    var v:number = percent ? percent : 0;
    if (v < 0) v = 0;
    if (v > 100) v = 100;
    return (
        <div className='gaugedistance__cont'>
            <div className='gaugedistance__gauge' style={{'width': (v) + "%"}}></div>
            <div className='gaugedistance__value'>{v.toFixed() + "%"}</div>
        </div>
    );
}
