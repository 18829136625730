import { FC, useEffect, useRef, useState } from 'react';
import { findcolor, RaceDatas } from '../types';
import './ranking.scss';

/* -------------------------------------------------------------------------------- *\
|                               Constantes
\* -------------------------------------------------------------------------------- */
const c_fontsize_label: number = 10;
const c_fontsize_number: number = 8;
/* -------------------------------------------------------------------------------- *\
|                               drawGraphRanking
\* -------------------------------------------------------------------------------- */
function drawGraphRanking(ctx: CanvasRenderingContext2D, width: number, height: number, ranking: RankingDatas[], distance: number, nbcomp: number)
{
    ctx.fillStyle = "black";
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fill();

    const xpcdep = width * 0.02;
    const xpcarr = width - 90;
    const yaxistop = 20;
    const yaxisbottom = height - 30;
    const yaxismargin = 5;
    const yaxismargin2 = 3;
    const gheight = yaxisbottom - yaxistop;

    ctx.strokeStyle = "white";
    ctx.lineWidth = 1;
    ctx.beginPath();
    ctx.moveTo(xpcdep, yaxistop);
    ctx.lineTo(xpcdep, yaxisbottom + yaxismargin);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(xpcarr, yaxistop);
    ctx.lineTo(xpcarr, yaxisbottom + yaxismargin);
    ctx.stroke();
    ctx.beginPath();
    ctx.moveTo(xpcdep, yaxisbottom);
    ctx.lineTo(xpcarr, yaxisbottom);
    ctx.stroke();

    ctx.fillStyle = "white";
    ctx.textAlign = "center";
    ctx.font = `${c_fontsize_label}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
    ctx.fillText("Start", xpcdep, yaxisbottom + yaxismargin + 5);
    ctx.fillText("Finish", xpcarr, yaxisbottom + yaxismargin + 5);

    var lastx = xpcarr;
    for (var d = 0; d < distance; d += 100)
    {
        var xt: number = xpcdep + (distance - d) * (xpcarr - xpcdep) / distance;
        ctx.beginPath();
        ctx.moveTo(xt, yaxisbottom - yaxismargin2);
        ctx.lineTo(xt, yaxisbottom + yaxismargin2);
        ctx.stroke();
        if (lastx - xt > 30)
        {
            ctx.fillText(d.toString(), xt, yaxisbottom + yaxismargin + 5);
        }
        lastx = xt;
    }

    ctx.font = `${c_fontsize_number}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    
    if (nbcomp > 0)
    {
        var lastxcomp: number = 0;
        var lastyr: number[] = new Array(nbcomp).fill(0);
        for (const comprank of ranking)
        {
            const x = xpcdep + (distance - comprank.distance) * (xpcarr - xpcdep) / distance;

            for (var icomp = 0; icomp < nbcomp; icomp++)
            {
                const ii = icomp + 1;
                var comp = comprank.competitors.find(n => n.number === ii);
                if (comp && comp.rank > 0)
                {
                    const color = findcolor(comp.number);
                    ctx.strokeStyle = '#' + color.color;
                    const y = yaxistop + ((comp.rank - 1)  / nbcomp) * gheight;

                    if (lastyr[icomp] > 0)
                    {
                        ctx.beginPath();
                        ctx.moveTo(lastxcomp, lastyr[icomp]);
                        ctx.lineTo(x, y);
                        ctx.stroke();                    
                    }
                    lastyr[icomp] = y;

                    ctx.beginPath();
                    ctx.fillStyle = '#' + color.color;
                    ctx.ellipse(x, y, 7, 7, 0, 0, 2 * Math.PI);
                    ctx.fill();

                    ctx.fillStyle = '#' + color.font;
                    ctx.fillText(String(comp.number), x, y);
                }
                else
                {
                    lastyr[icomp] = -1;
                }
            }
            lastxcomp = x;
        }
    }
}
/* -------------------------------------------------------------------------------- *\
|                               CompetitorRankingDatas
\* -------------------------------------------------------------------------------- */
class CompetitorRankingDatas
{
    public number: number = 0;
    public rank: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               RankingDatas
\* -------------------------------------------------------------------------------- */
class RankingDatas
{
    public name: string = "";
    public distance: number = 0;
    public competitors: CompetitorRankingDatas[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               SpeedsProps
\* -------------------------------------------------------------------------------- */
interface RankingProps
{
	race: RaceDatas,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               Speeds
\* -------------------------------------------------------------------------------- */
export const Ranking : FC<RankingProps> = ({race}) =>
{
    const refcontainer = useRef<HTMLDivElement>(null);
    const refcanvas = useRef<HTMLCanvasElement>(null);

    const [distance, setDistance] = useState(0);
    const [nbcomp, setNbcomp] = useState(0);
    const [width, setWidth] = useState(300);
    const [height] = useState(300);
    const [ranking, setRanking] = useState<RankingDatas[]>([]);

    useEffect(() =>
    {
        if (refcontainer && refcontainer.current && width !== refcontainer.current.clientWidth)
        {
            setWidth(refcontainer.current.clientWidth);
        }
        
        setDistance(race.distance);
        setNbcomp(race.positions.length);
        // eslint-disable-next-line
    }, [race]);

    useEffect(() =>
    {
        var sectionsdatas: RankingDatas[] = [];
        for(const position of race.pcs)
        {
            var section: RankingDatas = new RankingDatas();
            section.name = position.dcle;
            section.distance = position.distance;
            for (const run of position.runners)
            {
                var comp: CompetitorRankingDatas = new CompetitorRankingDatas();
                comp.number = run.number;
                comp.rank = run.rank;
                section.competitors.push(comp);
            }
            sectionsdatas.push(section);
        }
        setRanking(sectionsdatas);
    }, [race]);

    useEffect(() =>
    {
        const canvas = refcanvas.current;
        if (canvas)
        {
            const context = canvas.getContext('2d');
            if (context)
            {
                drawGraphRanking(context, width, height, ranking, distance, nbcomp);
            }
        }
    }, [width, height, ranking, distance, nbcomp]);

    return (
        <div className='ranking'>
            <div className='ranking__graph' ref={refcontainer}>
                <canvas ref={refcanvas} width={width} height={height}/>
            </div>
        </div>
    );
}
