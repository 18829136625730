import {  FundViewOutlined, LogoutOutlined } from '@ant-design/icons';
import { FC, ReactNode } from 'react';
import { LoginPage } from '../pages/login';
import { useAuthContext } from '../services/auth.service';
import './layout.scss';

/* -------------------------------------------------------------------------------- *\
|                               LayoutSite
\* -------------------------------------------------------------------------------- */
export const LayoutSite: FC<{ children?: ReactNode }> = (children) =>
{
	var auth = useAuthContext();
	if (!auth || !auth.token)
	{
		return <LoginPage />
	}

	return (
		<div className="layout">
			<div className="layout__sider" >
				<div className="layout__logo">
					<img src={'/images/logo.png'} alt="McLloyd" />
				</div> 
				<div className="layout__menucont">
					<div className="layout__menu">
						{/* <a className='layout__menu__item' href="/" ><CalendarOutlined /></a> */}
						<a className='layout__menu__item' href="/" ><FundViewOutlined /></a>
					</div>
					<div className="layout__menu layout__menu__bottom">
						<div className='layout__menu__item' onClick={() => auth.logout()}><LogoutOutlined /></div>
					</div>
				</div>
			</div>
			<div className="layout__content">
				<div className="layout__content__inner">
					<div>{children.children}</div>
					<div className="layout__footer">Version {`${process.env.REACT_APP_VERSION}`}</div>
				</div>
			</div>
	  </div>
	  );
}