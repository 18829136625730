import { FC, useEffect, useState } from 'react';
import { RaceDatas } from '../types';
import './distances.scss';
import { Nump } from './nump';
import { CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

/* -------------------------------------------------------------------------------- *\
|                               DistanceDatas
\* -------------------------------------------------------------------------------- */
class DistanceDatas
{
    public number: number = 0;
    public rank: number = 0;
    public eprm: number = 0;
    public delta: number = 0;
    public leftarrow: string = "transparent";
    public rightarrow: string = "transparent";
}
/* -------------------------------------------------------------------------------- *\
|                               DistancesProps
\* -------------------------------------------------------------------------------- */
interface DistancesProps
{
	race: RaceDatas,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               distance
\* -------------------------------------------------------------------------------- */
function distancetostring(distance: number): string
{
    if (distance === 0) return "";

    return distance.toFixed(1) + "m";
}
/* -------------------------------------------------------------------------------- *\
|                               Distances
\* -------------------------------------------------------------------------------- */
var olddistances: DistanceDatas[] = [];
export const Distances : FC<DistancesProps> = ({race}) =>
{
    const [distances, setDistances] = useState<DistanceDatas[]>([]);
    useEffect(() =>
    {
        const newdistances: DistanceDatas[] = race.positions.map((position) =>
        {
            const distance: DistanceDatas = new DistanceDatas();            
            distance.number = position.number;            
            distance.rank = position.rank;
            distance.eprm = position.eprm;

            const f = olddistances.find(x => x.number === distance.number);
            if (f)
            {
                distance.delta = 3.6 * 10 * (f.eprm - distance.eprm);
                const ad = Math.abs(distance.delta);
                var color: string = ad > 4 ? "red" : ad > 2 ? "orage" : "yellow";
                if (distance.delta < 0)
                {
                    distance.leftarrow = color;
                    distance.rightarrow = "transparent";
                }
                else
                {
                    distance.leftarrow = "transparent";
                    distance.rightarrow = color;
                }
            }
            return distance;
        }).sort((a, b) => a.number - b.number);
        setDistances(newdistances);
        olddistances = newdistances;
    }, [race]);

    return (
        <div className='distances'>
            <div className='distances__main'>
            {
                distances.map((distance, distance_index) => 
                {
                    if (distance.rank === 0)
                    {
                        return (
                            <div className='distances__main__line' key={'section_' + distance_index}>
                                <div className='distances__main__line__comp'>
                                    <Nump number={distance.number} size={18} />
                                    <div className='distances__main__line__nocomp'>Not in race</div>
                                </div>
                            </div>
                        )
                        }
                    const maxdistance: number = 35;
                    const right: string = 'calc(' + Math.min(distance.eprm, maxdistance) + '/' + maxdistance + ' * (100% - 82px))';
                    return (
                        <div className='distances__main__line' key={'section_' + distance_index}>
                            <div className='distances__main__line__comp' style={{right}}>
                                <div className='distances__main__line__eprm'>{distancetostring(distance.eprm)}</div>
                                <div className='distances__main__line__rank'>({distance.rank})</div>
                                {/* <div className='distances__main__line__rank'>{distance.delta.toFixed(2)}</div> */}
                                <CaretLeftOutlined className='distances__main__line__arrowleft' style={{color: distance.leftarrow}}></CaretLeftOutlined>
                                <Nump number={distance.number} size={18} />
                                <CaretRightOutlined className='distances__main__line__arrowright' style={{color: distance.rightarrow}}></CaretRightOutlined>
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </div>
    );
}
