import { FC, useEffect, useState } from 'react';
import { RaceDatas } from '../types';
import './sections.scss';
import { Nump } from './nump';

/* -------------------------------------------------------------------------------- *\
|                               CompetitorSectionDatas
\* -------------------------------------------------------------------------------- */
class CompetitorSectionDatas
{
    public number: number = 0;
    public tcle: number = 0;
    public rank: number = 0;
    public eprm: number = 0;
    public time: number = 0;
    public odd: number = 0;
    public avgspeed: number = 0;
    public maxspeed: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               SectionDatas
\* -------------------------------------------------------------------------------- */
class SectionDatas
{
    public name: string = "";
    public distance: number = 0;
    public competitors: CompetitorSectionDatas[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               SectionsProps
\* -------------------------------------------------------------------------------- */
interface SectionsProps
{
	race: RaceDatas,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               timetostring
\* -------------------------------------------------------------------------------- */
function timetostring(time_ms: number, full: boolean): string
{
    if (time_ms === 0) return "";

    var time_sec = time_ms / 1000;
    var time_m = Math.floor(time_sec / 60);
    var time_s = Math.floor(time_sec - time_m * 60);

    if (full || time_m > 0)
    {
        return time_m + ":" + time_s.toString().padStart(2, "0") + ":" + (time_ms % 1000).toString().padStart(2, "0");
    }
    return time_s.toString().padStart(2, "0") + ":" + (time_ms % 1000).toString().padStart(2, "0");
}
/* -------------------------------------------------------------------------------- *\
|                               speedtostringkmh
\* -------------------------------------------------------------------------------- */
function speedtostringkmh(speed_ms: number): string
{
    if (speed_ms === 0) return "";

    var speed_kmh = speed_ms * 3.6;
    return speed_kmh.toFixed(1);
}
/* -------------------------------------------------------------------------------- *\
|                               Sections
\* -------------------------------------------------------------------------------- */
export const Sections : FC<SectionsProps> = ({race}) =>
{
    const [sections, setSections] = useState<SectionDatas[]>([]);

    useEffect(() =>
    {
        var sectionsdatas: SectionDatas[] = [];
        for(const position of race.pcs)
        {
            var section: SectionDatas = new SectionDatas();
            section.name = position.dcle;
            section.distance = position.distance;
            for (const run of position.runners)
            {
                var comp: CompetitorSectionDatas = new CompetitorSectionDatas();
                comp.number = run.number;
                comp.rank = run.rank;
                comp.eprm = run.eprm;
                comp.time = run.time;
                comp.tcle = run.tcle;
                comp.odd = run.odd;
                comp.maxspeed = run.maxspeed;
                comp.avgspeed = run.avgspeed;
                section.competitors.push(comp);
            }
            // sectionsdatas.push(section);
            sectionsdatas = [section, ...sectionsdatas];
        }
        setSections(sectionsdatas);
    }, [race]);

    return (
        <div className='sections'>
            <div className='sections__main'>
                <div className='sections__cont'>
                {
                    sections.map((section, section_index) => 
                    {
                        const isdep: boolean = section.name === "PCDEP";

                        var style: string = '';
                        if (isdep) style += 'dep';
                        if (race && race.odds) style += 'withodds';

                        var pcname: string = section.name.substring(2) + "m";
                        if (section.name === "PCDEP") pcname = "Start"; 
                        if (section.name === "PCARR") pcname = "Finish"; 
                        return (
                            <div className={'sections__section' + (style ? " sections__section__" + style : "")} key={'section_' + section_index}>
                                <div className='sections__section__name'>{pcname}</div>
                                <div className='sections__section__header'>
                                    <div className='sections__section__competitor__rank'>rank</div>
                                    <div className='sections__section__competitor__number'>#</div>
                                    <div className='sections__section__competitor__eprm'>Dist/1st</div>
                                    { !isdep && <div className='sections__section__competitor__time'>section</div> }
                                    {!isdep && <div className='sections__section__competitor__tcle'>time</div>}
                                    {!isdep && <div className='sections__section__competitor__maxspeed'>section speed</div>}
                                    {!isdep && <div className='sections__section__competitor__maxspeed'>max speed</div>}
                                    {
                                        (race && race.odds)
                                        ? <div className='sections__section__competitor__odd'>odd</div>
                                        : <></>
                                    }
                                </div>
                                <div className='sections__section__competitors'>
                                {
                                    section.competitors.map((competitor, comp_index) => 
                                    {
                                        return (
                                            <div className='sections__section__competitor' key={'compsection_' + section_index + "_" +comp_index}>
                                                <div className='sections__section__competitor__rank'>{competitor.rank}</div>
                                                <div className='sections__section__competitor__number'><Nump number={competitor.number} size={18} /></div>
                                                <div className='sections__section__competitor__eprm'>{competitor.eprm}</div>
                                                {!isdep && <div className='sections__section__competitor__time'>{timetostring(competitor.time, false)}</div>}
                                                {!isdep && <div className='sections__section__competitor__tcle'>{timetostring(competitor.tcle, true)}</div>}
                                                {!isdep && <div className='sections__section__competitor__maxspeed'>{speedtostringkmh(competitor.avgspeed)}</div>}
                                                {!isdep && <div className='sections__section__competitor__maxspeed'>{speedtostringkmh(competitor.maxspeed)}</div>}
                                                {
                                                    (race && race.odds)
                                                    ? <div className='sections__section__competitor__odd'>{Math.floor(competitor.odd * 10) / 10}</div>
                                                    : <></>
                                                }
                                            </div>
                                        );
                                    })
                                }
                                </div>
                            </div>
                        )
                    })
                }
                </div>
            </div>
        </div>
    );
}
