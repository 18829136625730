import { FC, useEffect, useRef, useState } from 'react';
import { findcolor, RaceDatas, SpeedDatas } from '../types';
import './speeds.scss';
import { RunnerSpeedDatas, useSpeedsContext } from '../pages/race';
import { Switch } from 'antd';
import { Nump } from './nump';

/* -------------------------------------------------------------------------------- *\
|                               Constantes
\* -------------------------------------------------------------------------------- */
const c_fontsize_label: number = 10;
const c_fontsize_number: number = 8;
const c_percentdep: number = 0.10;
const c_xarr: number = 90;
const c_width: number = 300;
const c_height: number = 300;
const yaxismargin = 5;
const yaxismargin2 = 3;
const yaxismargin3 = 2;
const textmargin = 8;

const sbheight = 16;
const xsbmargin = 1;
const ysbmargin = 1;

/* -------------------------------------------------------------------------------- *\
|                               Vairables
\* -------------------------------------------------------------------------------- */
var g_mousex: number = -1;
var g_mouseselx1: number = -1;
var g_mouseselx2: number = 0;
var g_percent1: number = 0;
var g_percent2: number = 1;

var g_xfirst: number = c_width * 0.02;
var g_xpcdep: number = c_width * c_percentdep;
var g_xpcarr: number = c_width - c_xarr;
var g_yaxistop: number = 20;
var g_yaxisbottom: number = c_height - 50;

var g_ysbtop: number = g_yaxisbottom + 24;
// var g_ysbbottom: number = g_ysbtop + sbheight;
var g_sbactive: boolean = false;
var g_sbmove: boolean = false;
var g_sbfirstx: number = -1;

/* -------------------------------------------------------------------------------- *\
|                               RunnerSelection
\* -------------------------------------------------------------------------------- */
class RunnerSelection
{
    public number: number = 0;
    public selected: boolean = false;
}
/* -------------------------------------------------------------------------------- *\
|                               SpeedsProps
\* -------------------------------------------------------------------------------- */
interface SpeedsProps
{
	race: RaceDatas,
	children?: Node
}
/* -------------------------------------------------------------------------------- *\
|                               Speeds
\* -------------------------------------------------------------------------------- */
export const Speeds : FC<SpeedsProps> = ({race}) =>
{
    const refcontainer = useRef<HTMLDivElement>(null);
    const refcanvas = useRef<HTMLCanvasElement>(null);

    const [distance, setDistance] = useState<number>(0);
    const [width, setWidth] = useState<number>(300);
    const [height, setHeight] = useState<number>(300);
    const speeds = useSpeedsContext();

    const [showbefore, setShowbefore] = useState<boolean>(true);

    const [autoscale, setAutoscale] = useState<boolean>(false);
    const [follow, setFollow] = useState<boolean>(true);

    const [selected, setSelected] = useState<RunnerSelection[]>([]);

    /* -------------------------------------------------------------------------------- *\
    |                               drawGraph
    \* -------------------------------------------------------------------------------- */
    function drawGraph(ctx: CanvasRenderingContext2D)
    {
        g_xfirst = width * 0.02;
        g_xpcdep = showbefore ? c_xarr : (width * c_percentdep);
        g_xpcarr = width - c_xarr;
        g_yaxistop = 20;
        g_yaxisbottom = height - 50;
        const gheight = g_yaxisbottom - g_yaxistop;

        g_ysbtop = g_yaxisbottom + 24;
        // g_ysbbottom = g_ysbtop + sbheight;

        var speedytop = g_yaxistop;
        var speedheight = gheight;
        var axisy = g_yaxisbottom;
        var hrytop = g_yaxistop;
        var hrheight = gheight;

        ctx.fillStyle = "black";
        ctx.beginPath();
        ctx.rect(0, 0, width, height);
        ctx.fill();

        if (g_mouseselx1 !== g_mouseselx2)
        {
            var x1 = g_mouseselx1 + g_xpcdep;
            var x2 = g_mouseselx2 + g_xpcdep;
            if (x2 < x1)
            {
                var t = x1;
                x1 = x2;
                x2 = t;
            }
            ctx.fillStyle = "#444444";
            ctx.beginPath();
            ctx.rect(x1, 0, x2 - x1, g_yaxisbottom);
            ctx.fill();
        }

        ctx.strokeStyle = "white";
        ctx.lineWidth = 1;
        var drawstart: number = g_percent1 * distance;
        var drawend: number = g_percent2 * distance;

        const delta: number = (drawend - drawstart < 250) ? 25 : 100;

        var showhr: boolean = false;
        for(const speed of speeds)
        {
            if (!speed.speeds || !speed.selected) continue;

            if (showbefore)
            {
                for (const shr of speed.speeds.beforehrs)
                {
                    if (shr > 0) showhr = true;
                }
            }
            else
            {
                for (const ss of speed.speeds.racespeeds)
                {
                    var x: number = ss.dpdp;
                    x = (x - drawstart) / (drawend - drawstart);
                    x *=  (g_xpcarr - g_xpcdep);
                    x += g_xpcdep;

                    ss.workx = x;

                    if (ss.hr > 0) showhr = true;
                }
            }
        }

        if (showhr)
        {
            speedheight = gheight * 3 / 5;
            hrheight = gheight * 2 / 5 - 15;
            axisy = g_yaxistop + speedheight;
            hrytop = g_yaxistop + speedheight + 20;
        }

        ctx.beginPath();
        ctx.moveTo(g_xpcdep, g_yaxistop);
        ctx.lineTo(g_xpcdep, g_yaxisbottom + yaxismargin);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo(g_xpcarr, g_yaxistop);
        ctx.lineTo(g_xpcarr, g_yaxisbottom + yaxismargin);
        ctx.stroke();
        ctx.beginPath();
        ctx.moveTo((g_percent1 === 0 && !showbefore) ? g_xfirst : g_xpcdep, axisy);
        ctx.lineTo(g_xpcarr, axisy);
        ctx.stroke();

        if (showhr)
        {
            ctx.fillStyle = "#202020";
            ctx.beginPath();
            ctx.rect(0, axisy, width, 20);
            ctx.fill();
        }

        ctx.fillStyle = "white";
        ctx.textAlign = "center";
        ctx.font = `${c_fontsize_label}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;

        // Affichage des distances sous l'axe des xx
        if (!showbefore)
        {
            if (g_percent1 === 0)
            {
                ctx.textAlign = "right";
                ctx.fillText("Start", g_xpcdep, axisy + yaxismargin + textmargin);
            }
            if (g_percent2 === 1)
            {
                ctx.textAlign = "left";
                ctx.fillText("Finish", g_xpcarr, axisy + yaxismargin + textmargin);
            }
            ctx.textAlign = "center";
    
            var lastx = g_xpcarr;
            for (var d = 0; d < distance; d += delta)
            {
                var xt: number = (distance - d);
                xt = (xt - drawstart) / (drawend - drawstart);
                xt *=  (g_xpcarr - g_xpcdep);
                xt += g_xpcdep;

                if (xt >= g_xpcdep && xt <= g_xpcarr)
                {
                    ctx.beginPath();
                    ctx.moveTo(xt, axisy - yaxismargin2);
                    ctx.lineTo(xt, axisy + yaxismargin2);
                    ctx.stroke();
                    if (lastx - xt > 30)
                    {
                        ctx.fillText(d.toString(), xt, axisy + yaxismargin + textmargin);
                        lastx = xt;
                    }
                }
            }
        }

        var speedmin: number = 0;
        var speedmax: number = RunnerSpeedDatas.maxspeed;
        var hrmin: number = 0;
        var hrmax: number = RunnerSpeedDatas.maxhr + 10;

        const marginspeed: number = 5 / 3.6;
        const marginhr: number = 15;
        if (autoscale)
        {
            speedmin = RunnerSpeedDatas.maxspeed;
            speedmax = 0;
            hrmax = 0;
            hrmin = RunnerSpeedDatas.maxhr + 10;
            for (const speed of speeds)
            {
                if (!speed.speeds || !speed.selected) continue;

                if (showbefore)
                {
                    for (const ss of speed.speeds.beforespeeds)
                    {
                        speedmin = Math.min(ss, speedmin);
                        speedmax = Math.max(ss, speedmax);
                    }        
                    for (const shr of speed.speeds.beforehrs)
                    {
                        hrmin = Math.min(shr, hrmin);
                        hrmax = Math.max(shr, hrmax);
                    }        
                }
                else
                {
                    for (const ss of speed.speeds.racespeeds)
                    {
                        if (ss.workx >= g_xpcdep && ss.workx <= g_xpcarr)
                        {
                            speedmin = Math.min(ss.speed, speedmin);
                            speedmax = Math.max(ss.speed, speedmax);
                            hrmin = Math.min(ss.hr, hrmin);
                            hrmax = Math.max(ss.hr, hrmax);
                        }
                    }        
                }
            }
            speedmin = Math.max(0, speedmin - marginspeed);
            speedmax = Math.min(RunnerSpeedDatas.maxspeed, speedmax + marginspeed);
            hrmin = Math.max(0, hrmin - marginhr);
            hrmax = Math.min(RunnerSpeedDatas.maxhr, hrmax + marginhr);
        }

        if ((speedmax - speedmin) < marginspeed * 2)
        {
            speedmin = (speedmin + speedmax) / 2 - marginspeed;
            speedmax = (speedmin + speedmax) / 2 + marginspeed;
        }
        if (speedmin >= speedmax)
        {
            speedmin =  0;
            speedmax = RunnerSpeedDatas.maxodds;
        }

        if ((hrmax - hrmin) < marginhr * 2)
        {
            hrmin = (hrmin + hrmax) / 2 - marginhr;
            hrmax = (hrmin + hrmax) / 2 + marginhr;
        }
        if (hrmin >= hrmax)
        {
            hrmin =  0;
            hrmax = RunnerSpeedDatas.maxhr;
        }

        var speedminkmh: number = speedmin * 3.6;
        var speedmaxkmh: number = speedmax * 3.6;

        var unitspeed = 5;
        var modspeed = 10;
        if ((speedmaxkmh - speedminkmh) < 20)
        {
            unitspeed = 1;
            modspeed = 5;
        }

        var unithr = 25;
        var modhr = 50;
        if ((hrmax - hrmin) < 20)
        {
            unithr = 1;
            modhr = 5;
        }
        else if ((hrmax - hrmin) < 50)
        {
            unithr = 5;
            modhr = 10;
        }

        // Affichage des vitesses
        ctx.textAlign = "left";
        for (var u = 0; u <= speedmaxkmh; u += unitspeed)
        {
            const yu = speedytop + speedheight - ((u - speedminkmh) / (speedmaxkmh - speedminkmh)) * speedheight;

            if (yu >= 0 && yu <= speedheight)
            {
                ctx.beginPath();
                const tens: boolean = (u % modspeed) === 0;
                const m = tens ? yaxismargin2 : yaxismargin3;
                ctx.moveTo(g_xpcarr - m, yu);
                ctx.lineTo(g_xpcarr + m, yu);
                ctx.stroke();
                if (tens)ctx.fillText(u.toString() + " km/h", g_xpcarr + yaxismargin2 + 5, yu);
            }
        }
        if (showhr)
        {
            ctx.beginPath();
            ctx.moveTo((g_percent1 === 0 && !showbefore) ? g_xfirst : g_xpcdep, hrytop + hrheight);
            ctx.lineTo(g_xpcarr, hrytop + hrheight);
            ctx.stroke();
            for (u = 0; u <= hrmax; u += unithr)
            {
                const yu = hrheight - ((u - hrmin) / (hrmax - hrmin)) * hrheight;

                if (yu >= 0 && yu <= hrheight)
                {
                    ctx.beginPath();
                    const tens: boolean = (u % modhr) === 0;
                    const m = tens ? yaxismargin2 : yaxismargin3;
                    ctx.moveTo(g_xpcarr - m, hrytop + yu);
                    ctx.lineTo(g_xpcarr + m, hrytop + yu);
                    ctx.stroke();
                    if (tens) ctx.fillText(u.toString() + " bpm", g_xpcarr + yaxismargin2 + 5, hrytop + yu);
                }
            }
        }

        ctx.font = `${c_fontsize_number}px -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;

        ctx.save();
        const xclip: number = g_percent1 === 0 ? g_xfirst : g_xpcdep;
        ctx.rect(xclip, 0, g_xpcarr - xclip, height);
        ctx.clip();

        var i: number = 0;
        var xnum: number = 0;
        for(const speed of speeds)
        {
            if (!speed.speeds || !speed.selected) continue;

            const color = findcolor(speed.number);
            ctx.strokeStyle = '#' + color.color;
            xnum = g_xpcdep;
            ctx.beginPath();
            var lastspeed: number = 0;
            if (!showbefore)
            {
                if (g_percent1 === 0)
                {
                    var beforespeeds = [...speed.speeds.beforespeeds].splice(-SpeedDatas.c_nbbeforeracesmall);
                    console.log(beforespeeds.length, speed.speeds.beforespeeds.length);
                    for(i = 0; i < beforespeeds.length; i++)
                    {
                        const x = g_xfirst + i * (g_xpcdep - g_xfirst) / (SpeedDatas.c_nbbeforeracesmall - 1);
                        var y = speedytop + speedheight - ((beforespeeds[i] - speedmin) / (speedmax - speedmin)) * speedheight;
                        if (y >= speedytop + speedheight) y = speedytop + speedheight;
                        if (i === 0)
                        {
                            ctx.moveTo(x, y);
                        }
                        else
                        {
                            ctx.lineTo(x, y);
                        }
                        lastspeed = beforespeeds[i];
                    }
                }
                if (distance > 0)
                {
                    for (i = 0; i < speed.speeds.racespeeds.length; i++)
                    {
                        const o: number = i < speed.speeds.racespeeds.length - 1 ? speed.speeds.racespeeds[i].speed : speed.speed;
                        x = speed.speeds.racespeeds[i].workx;
                        const y = speedytop + speedheight - ((o - speedmin) / (speedmax - speedmin)) * speedheight;
                        if (lastspeed === 0 && i === 0)
                        {
                            ctx.moveTo(x, y);
                        }
                        else
                        {
                            ctx.lineTo(x, y);
                        }
                        xnum = x;
                    }
                }
            }
            else
            {
                // Les vitesses
                for(i = 0; i < speed.speeds.beforespeeds.length; i++)
                {
                    const x = g_xpcdep + i * (g_xpcarr - g_xpcdep) / (SpeedDatas.c_nbbeforerace - 1);
                    const y = speedytop + speedheight - ((speed.speeds.beforespeeds[i] - speedmin) / (speedmax - speedmin)) * speedheight;
                    if (i === 0)
                    {
                        ctx.moveTo(x, y);
                    }
                    else
                    {
                        ctx.lineTo(x, y);
                    }
                    lastspeed = speed.speeds.beforespeeds[i];
                    xnum = g_xpcarr;
                }
            }
            ctx.stroke();
            speed.last_x = xnum;
            speed.last_y = speedytop + speedheight - ((speed.speed  - speedmin) / (speedmax - speedmin)) * speedheight;
        }
        if (showhr)
        {
            ctx.lineWidth = 1;
            for(const speed of speeds)
            {
                if (!speed.speeds || !speed.selected) continue;

                const color = findcolor(speed.number);
                ctx.strokeStyle = '#' + color.color;
                xnum = g_xpcdep;
                ctx.beginPath();
                var lasthr: number = 0;
                if (!showbefore)
                {
                    if (g_percent1 === 0)
                    {
                        var beforehrs = [...speed.speeds.beforehrs].splice(-SpeedDatas.c_nbbeforeracesmall);
                        for(i = 0; i < beforehrs.length; i++)
                        {
                            const x = g_xfirst + i * (g_xpcdep - g_xfirst) / (SpeedDatas.c_nbbeforeracesmall - 1);
                            var yy = hrytop + hrheight - ((beforehrs[i] - hrmin) / (hrmax - hrmin)) * hrheight;
                            if (yy >= hrytop + hrheight) yy = hrytop + hrheight;
                            if (i === 0)
                            {
                                ctx.moveTo(x, yy);
                            }
                            else
                            {
                                ctx.lineTo(x, yy);
                            }
                            lasthr = beforehrs[i];
                        }
                    }
                    if (distance > 0)
                    {
                        for (i = 0; i < speed.speeds.racespeeds.length; i++)
                        {
                            const o: number = i < speed.speeds.racespeeds.length - 1 ? speed.speeds.racespeeds[i].hr : speed.hr;
                            x = speed.speeds.racespeeds[i].workx;
                            const yy = hrytop + hrheight - ((o - hrmin) / (hrmax - hrmin)) * hrheight;
                            if (lasthr === 0 && i === 0)
                            {
                                ctx.moveTo(x, yy);
                            }
                            else
                            {
                                ctx.lineTo(x, yy);
                            }
                            xnum = x;
                        }
                    }
                }
                else
                {
                    for(i = 0; i < speed.speeds.beforespeeds.length; i++)
                    {
                        const x = g_xpcdep + i * (g_xpcarr - g_xpcdep) / (SpeedDatas.c_nbbeforerace - 1);
                        const y = hrytop + hrheight - ((speed.speeds.beforehrs[i] - hrmin) / (hrmax - hrmin)) * hrheight;
                        if (i === 0)
                        {
                            ctx.moveTo(x, y);
                        }
                        else
                        {
                            ctx.lineTo(x, y);
                        }
                        lasthr = speed.speeds.beforehrs[i];
                        xnum = g_xpcarr;
                    }
                }
                ctx.stroke();
                speed.last_xhr = xnum;
                speed.last_yhr = hrytop + hrheight - ((speed.hr  - hrmin) / (hrmax - hrmin)) * hrheight;
            }
            ctx.lineWidth = 1;
        }
        ctx.restore();
        
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        
        for(const speed of speeds)
        {
            if (!speed.speeds || !speed.selected) continue;

            speed.last_x = Math.min(speed.last_x, g_xpcarr);
            if (speed.last_x > g_xpcdep || g_percent1 === 0)
            {
                const color = findcolor(speed.number);

                ctx.beginPath();
                ctx.fillStyle = '#' + color.color;
                ctx.ellipse(speed.last_x, speed.last_y, 7, 7, 0, 0, 2 * Math.PI);
                ctx.fill();

                ctx.fillStyle = '#' + color.font;
                ctx.fillText(String(speed.number), speed.last_x, speed.last_y);

                if (showhr)
                {
                    ctx.beginPath();
                    ctx.fillStyle = '#' + color.color;
                    ctx.ellipse(speed.last_xhr, speed.last_yhr, 7, 7, 0, 0, 2 * Math.PI);
                    ctx.fill();

                    ctx.fillStyle = '#' + color.font;
                    ctx.fillText(String(speed.number), speed.last_xhr, speed.last_yhr);
                }
            }
        }

        // Curseur
        if (g_mousex >= 0)
        {        
            ctx.strokeStyle = "white";
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(g_mousex + (showbefore ? c_xarr : g_xpcdep), 0);
            ctx.lineTo(g_mousex + (showbefore ? c_xarr : g_xpcdep), g_yaxisbottom);
            ctx.stroke();
        }

        if (!showbefore)
        {
            // Scrollbar
            ctx.fillStyle = "#262626";
            ctx.beginPath();
            ctx.rect(g_xpcdep, g_ysbtop, g_xpcarr - g_xpcdep, sbheight);
            ctx.fill();

            var [xsb, ysb, wsb, hsb] = computesb();
            ctx.fillStyle = (g_sbactive || g_sbmove) ? "#e0a642" : "#a78349";
            ctx.beginPath();
            ctx.rect(xsb, ysb, wsb, hsb);
            ctx.fill();
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               computex
    \* -------------------------------------------------------------------------------- */
    function computex(x: number, width: number): number
    {
        const xpcdep = showbefore ? c_xarr : width * c_percentdep;
        const xpcarr = width - c_xarr;
        if (x < xpcdep) x = xpcdep;
        if (x > xpcarr) x = xpcarr;

        return x - xpcdep;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               computesb
    \* -------------------------------------------------------------------------------- */
    function computesb(): [number, number, number, number]
    {
        const hsbtotal = sbheight - ysbmargin * 2;
        const wsbtotal = (g_xpcarr - g_xpcdep) - xsbmargin * 2;
        const xsb = wsbtotal * g_percent1 + g_xpcdep + xsbmargin;
        const wsb = wsbtotal * (g_percent2 - g_percent1);
        return [xsb, g_ysbtop + ysbmargin, wsb, hsbtotal];
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseMove
    \* -------------------------------------------------------------------------------- */
    function handleMouseMove(e: React.MouseEvent, width: number)
    {
        if (g_mouseselx1 >= 0)
        {
            g_mousex = computex(e.nativeEvent.offsetX, width);
            g_mouseselx2 = g_mousex;
        }
        else if (e.nativeEvent.offsetY < g_yaxisbottom)
        {
            g_mousex = computex(e.nativeEvent.offsetX, width);
        }
        else
        {
            g_mousex = -1;
        }

        if (!g_sbmove)
        {
            var [xsb, ysb, wsb, hsb] = computesb();
            if (e.nativeEvent.offsetX >= xsb && e.nativeEvent.offsetX <= xsb + wsb && e.nativeEvent.offsetY >= ysb && e.nativeEvent.offsetY <= ysb + hsb)
            {
                g_sbactive = true;
            }
            else
            {
                g_sbactive = false;
            }
        }
        else
        {
            const wsbtotal = (g_xpcarr - g_xpcdep) - xsbmargin * 2;
            const wsb = wsbtotal * (g_percent2 - g_percent1);
            const xsb = wsbtotal * g_percent1;

            var d = e.nativeEvent.offsetX - g_sbfirstx;
            var nx = xsb + d;
            if (nx < 0) nx = 0;
            if (nx + wsb > wsbtotal) nx = wsbtotal - wsb;

            g_percent1 = nx / wsbtotal;
            g_percent2 = (nx + wsb) / wsbtotal;

            g_sbfirstx = e.nativeEvent.offsetX;
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseLeave
    \* -------------------------------------------------------------------------------- */
    function handleMouseLeave(e: React.MouseEvent, width: number)
    {
        g_mousex = -1;
        g_mouseselx1 = -1;
        g_mouseselx2 = -1;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dozoomfull
    \* -------------------------------------------------------------------------------- */
    function dozoomfull()
    {
        g_percent1 = 0;
        g_percent2 = 1;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dozoomout
    \* -------------------------------------------------------------------------------- */
    function dozoomout()
    {
        var d: number = g_percent2 - g_percent1;
        g_percent1 = g_percent1 - d / 2;
        g_percent2 = g_percent2 + d / 2;
        if (g_percent1 < 0) g_percent1 = 0;
        if (g_percent2 > 1) g_percent2 = 1;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dozoomin
    \* -------------------------------------------------------------------------------- */
    function dozoomin()
    {
        var d: number = g_percent2 - g_percent1;
        var middle: number = (g_percent1 + g_percent2) / 2;
        var p1 = middle - d / 4;
        var p2 = middle + d / 4;
        if (p1 < 0) p1 = 0;
        if (p2 > 1) p2 = 1;

        if ((p2 - p1) > 0.05)
        {
            g_percent1 = p1;
            g_percent2 = p2;
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseDown
    \* -------------------------------------------------------------------------------- */
    function handleMouseDown(e: React.MouseEvent, width: number)
    {
        if (showbefore) return;

        if (e.nativeEvent.offsetY < g_yaxisbottom)
        {
            if (e.nativeEvent.button === 0)
            {
                g_mouseselx1 = computex(e.nativeEvent.offsetX, width);
                g_mouseselx2 = g_mouseselx1;
                e.stopPropagation();
            }
            if (e.nativeEvent.button === 2)
            {
                dozoomout();
                e.stopPropagation();
            }
        }
        var [xsb, ysb, wsb, hsb] = computesb();
        if (e.nativeEvent.offsetX >= xsb && e.nativeEvent.offsetX <= xsb + wsb && e.nativeEvent.offsetY >= ysb && e.nativeEvent.offsetY <= ysb + hsb)
        {
            g_sbfirstx = e.nativeEvent.offsetX;
            g_sbmove = true;
            setFollow(false);
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               handleMouseUp
    \* -------------------------------------------------------------------------------- */
    function handleMouseUp(e: React.MouseEvent, width: number)
    {
        if (e.nativeEvent.button === 0)
        {
            if (width > 0 && g_mouseselx1 >= 0)
            {
                g_mouseselx2 = computex(e.nativeEvent.offsetX, width);
                const xpcdep = width * c_percentdep;
                const xpcarr = width - c_xarr;
                var p1 = g_mouseselx1 / (xpcarr - xpcdep);
                var p2 = g_mouseselx2 / (xpcarr - xpcdep);
                if (p2 < p1)
                {
                    var tmp = p1;
                    p1 = p2;
                    p2 = tmp;
                }
                if ((p2 - p1) > 0.05)
                {
                    var oldw: number = g_percent2 - g_percent1;
                    var selw: number = p2 - p1;
                    if (oldw > 0)
                    {
                        g_percent1 += p1 * oldw;
                        g_percent2 = g_percent1 + selw * oldw;
                    }
                }
            }
            g_mouseselx1 = -1;
            g_mouseselx2 = -1;

            if (g_sbmove)
            {
                g_sbmove = false;
            }
        }
        if (e.nativeEvent.button === 2)
        {
            e.stopPropagation();
        }
    }

    /* -------------------------------------------------------------------------------- *\
    |                               doselectall
    \* -------------------------------------------------------------------------------- */
    function doselectall()
    {
        const sel = selected.map((x) => {x.selected = true; return x;});
        setSelected(sel);
    }
    /* -------------------------------------------------------------------------------- *\
    |                               dodeselectall
    \* -------------------------------------------------------------------------------- */
    function dodeselectall()
    {
        const sel = selected.map((x) => {x.selected = false; return x;});
        setSelected(sel);
    }

    useEffect(() =>
    {
        if (refcontainer && refcontainer.current && width !== refcontainer.current.clientWidth)
        {
            setWidth(refcontainer.current.clientWidth);
        }
        if (refcontainer && refcontainer.current && height !== refcontainer.current.clientHeight)
        {
            setHeight(refcontainer.current.clientHeight);
        }
        
        var sel: RunnerSelection[] = [];
        setDistance(race.distance);

        var diff: boolean = false;
        for (const p of race.positions)
        {
            if (!selected.find((x) => x.number === p.number))
            {
                diff = true;
                sel.push({number: p.number, selected: true});
            }
        }
        if (diff)
        {
            sel.sort((a, b) => a.number - b.number);
            setSelected(sel);
        }

        const sb: boolean = !race.finished && !race.started;
        if (sb !== showbefore)
        {
            setShowbefore(sb);
        }
        // eslint-disable-next-line
    }, [race]);

    useEffect(() =>
    {
        const canvas = refcanvas.current;
        if (canvas)
        {
            for (const speed of speeds)
            {
                if (speed)
                {
                    speed.selected = selected.find((x) => x.number === speed.number)?.selected ? true : false;
                }
            }
            if (follow && distance > 0)
            {
                var dpdpmax: number = 0;
                for (const speed of speeds)
                {
                    if (speed && speed.speeds && speed.speeds.racespeeds.length > 0 && speed.selected)
                    {
                        const sd: number = speed.speeds.racespeeds[speed.speeds.racespeeds.length - 1].dpdp;
                        if (sd > dpdpmax)
                        {
                            dpdpmax = sd;
                        }
                    }
                }
                var percent: number = dpdpmax / distance;
                const deltamid: number = (g_percent2 - g_percent1) / 2;

                if (percent + deltamid > 1)
                {
                    percent = 1 - deltamid;
                }
                if (percent - deltamid < 0)
                {
                    percent = deltamid;
                }
                g_percent1 = percent - deltamid;
                g_percent2 = percent + deltamid;
            }
            const context = canvas.getContext('2d');
            if (context)
            {
                drawGraph(context);
            }
        }
        // eslint-disable-next-line
    }, [width, height, speeds, distance, autoscale, follow, selected]);

    return (
        <div className='speeds'>
            <div className='speeds__selection'>
                {
                    selected.map((s, i) =>
                    {
                        return (
                            <div className='speeds__selection__runner' key={`sel_${i}`} >
                                <Nump number={s.number} size={16} />
                                <Switch size="small" checked={s.selected} onChange={() => setSelected(selected.map((x) => x.number === s.number ? {number: x.number, selected: !x.selected} : x))} />
                            </div>
                        )
                    })
                }
                <div className='speeds__selection__button' onClick={() => doselectall()}>select all</div>
                <div className='speeds__selection__button' onClick={() => dodeselectall()}>deselect all</div>
            </div>
            <div className='speeds__datas'>
                <div className='speeds__datas__logo'>
                    {
                        (race && race.hrs) ?
                        <img className={"speeds__datas__logo__speed" + (showbefore ? "" : " speeds__datas__logo__inrace")} src={'/images/speed.svg'} alt=""/>
                        : <></>
                    }
                    {
                        (race && race.hrs) ?
                        <img className={"speeds__datas__logo__heart" + (showbefore ? "" : " speeds__datas__logo__inrace")} src={'/images/heart-beat.svg'} alt=""/>
                        : <></>
                    }
                </div>
                <div className='speeds__datas__graph' ref={refcontainer}>
                    <canvas ref={refcanvas} width={width} height={height}
                    onContextMenu={e => e.preventDefault()}
                    onMouseMove={e => handleMouseMove(e, width)}
                    onMouseDownCapture ={e => handleMouseDown(e, width)}
                    onMouseUp={e => handleMouseUp(e, width)}
                    onMouseLeave={e => handleMouseLeave(e, width)}/>
                </div>
                <div className='speeds__datas__toolbar'>
                    {
                        !showbefore ?
                        (
                            <>
                                <div className='speeds__datas__toolbar__button' onClick={() => dozoomin()}>Zoom in</div>
                                <div className='speeds__datas__toolbar__button' onClick={() => dozoomout()}>Zoom out</div>
                                <div className='speeds__datas__toolbar__button' onClick={() => dozoomfull()}>Full</div>
                                <div className='speeds__datas__toolbar__switch'>
                                    <div>follow</div>
                                    <Switch size="small" checked={follow} onChange={() => setFollow(!follow)} />
                                </div>
                            </>
                        )
                        :
                        <></>
                    }
                    <div className='speeds__datas__toolbar__switch'>
                        <div>scale</div>
                        <Switch size="small" checked={autoscale} onChange={() => setAutoscale(!autoscale)} />
                    </div>
                </div>
            </div>
        </div>
    );
}
