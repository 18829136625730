import { FC, ReactNode } from 'react';
import './nump.scss';
import { findcolor } from '../types';

/* -------------------------------------------------------------------------------- *\
|                               Loader
\* -------------------------------------------------------------------------------- */
export const Nump : FC<{number: number, size: number, children?: ReactNode}> = ({number, size}) =>
{
	const color = findcolor(number);
	return (
		<div className='nump' style={{'width': `${size}px`, 'height': `${size}px`}}>
			<div className='nump__circle'  style={{'background': `#${color.color}`, 'border': `1px solid #${color.border}`}}></div>
			<div className='nump__number' style={{'color': `#${color.font}`}}>{number}</div>
		</div>
	);
}