
/* -------------------------------------------------------------------------------- *\
|                               ApiElement
\* -------------------------------------------------------------------------------- */
export class ApiElement
{
    public code: string = "";
    public uuid: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               ApiRunner
\* -------------------------------------------------------------------------------- */
export class ApiRunner
{
    public uuid: string = "";
    public name: string = "";
    public draw: number = 0;
    public number: number = 0;
    public status: string = "";
    public distance: number = 0;
    public driver: string = "";
    public trainer: string = "";
    public owner: string = "";
    public rankTracked: number = 0;
    public timeTracked: number = 0;
    public rankOfficial: number = 0;
    public timeOfficial: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               ApiRace
\* -------------------------------------------------------------------------------- */
export class ApiRace
{
    public date: string = "";
    public distance: number = 0;
    public hours: string = "";
    public label: string = "";
    public meeting: number = 0;
    public number: number = 0;
    public source: ApiElement = new ApiElement();
    public starters: number = 0;
    public track: ApiElement = new ApiElement();
    public type: string = "";
    public uuid: string = "";
    public venue: ApiElement = new ApiElement();
    public competitors: ApiRunner[] = [];

    public start: number = 0; // start time in minute for midnight
}
/* -------------------------------------------------------------------------------- *\
|                               ApiActiveRace
\* -------------------------------------------------------------------------------- */
export class ApiActiveRace
{
    public date: string = "";
    public sic: string = "";
    public number: number = 0;
    public lastdata: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               ApiMeeting
\* -------------------------------------------------------------------------------- */
export class ApiMeeting
{
    public code: string = "";
    public date: string = "";
    public number: number = 0;
    public races: ApiRace[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               RaceTime
\* -------------------------------------------------------------------------------- */
export class RaceTime
{
    public Time: number = 0;
    public Number: number = 0;
    public Sic: string = "";
    public StartTime: string = "";
    public Name: string = "";
}
/* -------------------------------------------------------------------------------- *\
|                               formatdateYYYYMMDD
\* -------------------------------------------------------------------------------- */
export const formatdateYYYYMMDD = (date: Date): string =>
{
    const y: number = date.getFullYear();
    const m: number = date.getMonth() + 1;
    const d: number = date.getDate();

    return String(y).padStart(4, '0') + String(m).padStart(2, '0') + String(d).padStart(2, '0');
}
/* -------------------------------------------------------------------------------- *\
|                               RunnerPC
\* -------------------------------------------------------------------------------- */
export class RunnerPC
{
    public number: number = 0;
    public avgspeed: number = 0;
    public maxspeed: number = 0;
    public rank: number = 0;
    public tcle: number = 0;
    public eprm: number = 0;
    public drdp: number = 0;
    public dpdp: number = 0;
    public time: number = 0;
    public odd: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               SpeedData
\* -------------------------------------------------------------------------------- */
export class SpeedData
{
    public speed: number = 0;
    public dpdp: number = 0;
    public odd: number = 0;
    public hr: number = 0;

    public workx: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               SpeedDatas
\* -------------------------------------------------------------------------------- */
export class SpeedDatas
{
    public lastrace: number = 0;
    public beforespeeds: number[] = [];
    public beforehrs: number[] = [];
    public racespeeds: SpeedData[] = [];
    public static c_nbbeforerace: number = 2000;
    public static c_nbbeforeracesmall: number = 100;
    public instantspeed: number[] = [0];
    public smoothspeed: number = 0;
    public maxspeed: number = 0;
    public maxodds: number = 0;
    public maxhr: number = 0;
}
/* -------------------------------------------------------------------------------- *\
|                               RunnerPosition
\* -------------------------------------------------------------------------------- */
export class RunnerPosition
{
    public number: number = 0;
    public state: string = "";
    public lat: number = 0;
    public lng: number = 0;
    public rank: number = 0;
    public drdp: number = 0;
    public dpdp: number = 0;
    public eprm: number = 0;
    public speed: number = 0;
    public hr: number = 0;
    public maxspeed: number = 0;
    public odds: number = 0;
    public speeds: SpeedDatas = new SpeedDatas();
}
/* -------------------------------------------------------------------------------- *\
|                               RunnerPCInfos
\* -------------------------------------------------------------------------------- */
export class RunnerPCInfos
{
    public dcle: string = "";
    public distance: number = 0;
    public runners: RunnerPC[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               RaceInfos
\* -------------------------------------------------------------------------------- */
export class RaceDatas
{
    public raceno: number = 0;
    public date: string = "";
    public track: string = "";
    public ts: number = 0;
    public distance: number = 0;
    public showeddistance: number = 0;
    public position: number = 0;
    public started: boolean = false;
    public finished: boolean = false;
    public positions: RunnerPosition[] = [];
    public pcs: RunnerPCInfos[] = [];
    public odds: boolean = false;
    public hrs: boolean = false;
}
/* -------------------------------------------------------------------------------- *\
|                               RunnerInfos
\* -------------------------------------------------------------------------------- */
export class RunnerDatas
{
    public raceno: number = 0;
    public date: string = "";
    public track: string = "";
    public positions: RunnerPosition[] = [];
}
/* -------------------------------------------------------------------------------- *\
|                               findcolor
\* -------------------------------------------------------------------------------- */
export function findcolor(num: number)
{
    let color = { color: '', border: '', font: '' };
    const number = isNaN(Number(num)) ? -1 : (Number(num - 1) % 21) + 1;
    switch (number)
    {
        case 1:
            color.color = 'ff557b';
            color.border = 'ff557b';
            color.font = 'ffffff';
            break;
        case 2:
            color.color = 'dddddd';
            color.border = '373633';
            color.font = '373633';
            break;
        case 3:
            color.color = '0084c9';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 4:
            color.color = 'f7fc0f';
            color.border = '373633';
            color.font = '373633';
            break;
        case 5:
            color.color = '4b7f56';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 6:
            color.color = '3f3e36';
            color.border = 'ffffff';
            color.font = 'f3fc57';
            break;
        case 7:
            color.color = 'ff6e6e';
            color.border = 'ffffff';
            color.font = '443029';
            break;
        case 8:
            color.color = 'fc6d87';
            color.border = 'ffffff';
            color.font = '443029';
            break;
        case 9:
            color.color = '008eba';
            color.border = 'ffffff';
            color.font = '443029';
            break;
        case 10:
            color.color = '8669a5';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 11:
            color.color = 'd7d4c7';
            color.border = 'ffffff';
            color.font = 'e77787';
            break;
        case 12:
            color.color = '4ebf5f';
            color.border = 'ffffff';
            color.font = '2b3d20';
            break;
        case 13:
            color.color = '624742';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 14:
            color.color = '7c4c57';
            color.border = 'ffffff';
            color.font = 'fcf84d';
            break;
        case 15:
            color.color = 'ada68c';
            color.border = 'ffffff';
            color.font = '3e362d';
            break;
        case 16:
            color.color = '8febee';
            color.border = 'ffffff';
            color.font = 'fc6082';
            break;
        case 17:
            color.color = '4a585f';
            color.border = 'ffffff';
            color.font = 'ffffff';
            break;
        case 18:
            color.color = '454d47';
            color.border = 'ffffff';
            color.font = 'f3fb48';
            break;
        case 19:
            color.color = '56637d';
            color.border = 'ffffff';
            color.font = 'e8638a';
            break;
        case 20:
            color.color = 'fe607d';
            color.border = 'ffffff';
            color.font = 'fedc66';
            break;
        case 21:
            color.color = 'a7a1c3';
            color.border = 'ffffff';
            color.font = '494d5b';
            break;
        default:
            color.color = 'a7a1c3';
            color.border = 'ffffff';
            color.font = '494d5b';
            break;
    }
    return color;
}