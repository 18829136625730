import { findwsserverurl } from "./services/api.service";

/* -------------------------------------------------------------------------------- *\
|                               WebClient
\* -------------------------------------------------------------------------------- */
export class WebClient
{
    private addr: string;
    public ws: WebSocket | null = null;
    public timer: NodeJS.Timer | null = null;
    public cb_connect: (ws: WebClient) => void;
    public cb_message: (cmd: string, datas: any) => void;
    private lastmsg: Date = new Date();

    /* -------------------------------------------------------------------------------- *\
    |                               constructor
    \* -------------------------------------------------------------------------------- */
    constructor(cb_connect: (ws: WebClient) => void, cb_message: (cmd: string, datas: any) => void)
    {
        this.addr = findwsserverurl();
        this.ws = null;

        this.cb_connect = cb_connect;
        this.cb_message = cb_message;
    }
    /* -------------------------------------------------------------------------------- *\
    |                               connect
    \* -------------------------------------------------------------------------------- */
    connect()
    {
        if (this.ws)
        {
            // console.log("already connected");
            return;
        }

        let url = new URL('', this.addr);
        if (url.protocol === 'https')
        {
            url.protocol = url.protocol.replace('https', 'wss');
        }
        else
        {
            url.protocol = url.protocol.replace('http', 'ws');
        }

        // console.log('connect ' + url.href);
        this.ws = new WebSocket(url.href);
        let self = this;
        this.ws.onopen = function ()
        {
            // console.log('this.ws.onopen');
            self.cb_connect(self);
            if (!self.timer)
            {
                self.timer = setInterval(() => self.keep_alive(), 10000);
            }
        };

        this.ws.onmessage = function (event)
        {
            // console.log('this.ws.onmessage');
            self.lastmsg = new Date();
            let msg = JSON.parse(event.data);
            self.cb_message(msg.cmd, msg.datas);
        }

        this.ws.onerror = function ()
        {
            // console.log('this.ws.onerror');
            if (self.ws)
            {
                self.ws.close();
                self.ws = null;
            }
        };

        this.ws.onclose = function ()
        {
            // console.log('this.ws.onclose');
            if (self.ws)
            {
                self.ws.close();
                self.ws = null;
            }
            self.cb_message('closed', '');
        };
    }
    /* -------------------------------------------------------------------------------- *\
    |                               keep_alive
    \* -------------------------------------------------------------------------------- */
    keep_alive()
    {
        if (!this.ws || this.ws.readyState >= 2)
        {
            // console.log('keep_alive connect', this.ws?.readyState);
            this.close();
            this.connect();
        }
        else if (this.lastmsg.getTime() + 20 * 1000 < new Date().getTime())
        {
            this.send_cmd('ping', '');
        }
    }
    /* -------------------------------------------------------------------------------- *\
    |                               close
    \* -------------------------------------------------------------------------------- */
    close()
    {
        if (this.ws)
        {
            this.ws.close();
            this.ws = null;
            if (this.timer)
            {
                clearInterval(this.timer);
                this.timer = null;
            }
        }
    };
    /* -------------------------------------------------------------------------------- *\
    |                               send_cmd
    \* -------------------------------------------------------------------------------- */
    send_cmd(cmd: string, datas: any)
    {
        if (this.ws === null)
        {
            console.error("send_cmd ws_engine socket not open !");
            return;
        }
        let self = this;
        this.wait_connection(function () { if (self.ws) self.ws.send(JSON.stringify({ cmd: cmd, datas: datas }))}, 100);
    };
    /* -------------------------------------------------------------------------------- *\
    |                               wait_connection
    \* -------------------------------------------------------------------------------- */
    wait_connection(callback: () => void, interval: number)
    {
        if (this.ws && this.ws.readyState === 1)
        {
            callback();
        }
        else
        {
            let that = this;
            setTimeout(function () { that.wait_connection(callback, interval) }, interval)
        }
    }
}