import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './app.scss';
import { CalendarPage } from './pages/calendar';
import { LoginPage } from './pages/login';
import { RacePage } from './pages/race';
import { AuthProvider } from './services/auth.service';
import { useContext, createContext, useState, useEffect } from 'react';

/* -------------------------------------------------------------------------------- *\
|                               ClockContext
\* -------------------------------------------------------------------------------- */
export const ClockContext = createContext<string>("");
export const useClockContext = () =>
{
    return useContext(ClockContext);
};
/* -------------------------------------------------------------------------------- *\
|                               App
\* -------------------------------------------------------------------------------- */
function App()
{
    const [clock, setClock] = useState<string>('');

    /* -------------------------------------------------------------------------------- *\
    |                               refreshTime
    \* -------------------------------------------------------------------------------- */
    function refreshTime()
    {
        const now = new Date();
        setClock(now.toLocaleTimeString());
    }
    useEffect(() => {
        const interval = setInterval(() =>
        {
            refreshTime();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <BrowserRouter basename="/">
            <ClockContext.Provider value={clock}>
                <AuthProvider>
                    <Routes>
                        <Route path="/" element={<CalendarPage />} />
                        <Route path="/meetings" element={<CalendarPage />} />
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/race/:date/:track/:raceno" element={<RacePage />} />
                    </Routes>
                </AuthProvider>
            </ClockContext.Provider>
        </BrowserRouter>
    );
}

export default App;
